<template>
  <VanSticky :offset-top="0">
    <div class="top-menu sticky-menu py-2 no_mobile">
      <div class="container">
        <div class="d-flex justify-content-center align-items-center">
          <div class="top-menu--right col-md-4 justify-end align-items-center">
            <div class="menu-left-menu-container">
              <ul id="menu-left-menu" class="menu" v-if="left_menu">
                <li

                    v-for="item in left_menu?.menuItems?.nodes"
                    class="menu-item"
                    :class="{ 'current-menu-item': item.uri == route.fullPath }"
                >
                  <NuxtLink :to="item.uri">{{ item.label }}</NuxtLink>
                </li>
              </ul>
            </div>
          </div>
          <div class="logo col-md-2">
            <NuxtLink to="/" class="">
              <nuxt-img
                  class="mx-auto"
                  v-if="options?.logo?.node"
                  :src="options?.logo?.node?.sourceUrl"
                  :alt="options.logo?.node?.altText"
              />
            </NuxtLink>
          </div>
          <div class="top-menu--left col-md-4 d-flex justify-between align-items-center mr-5">
            <div class="menu-right-menu-container">
              <ul id="menu-right-menu" class="menu" v-if="right_menu">
                <li
                    class="menu-item"
                    :class="{ 'current-menu-item': stripUrl(item.uri) == route.path }"
                    v-for="item in right_menu?.menuItems?.nodes"
                >
                  <NuxtLink :to="item.uri">{{ item.label }}</NuxtLink>
                </li>
              </ul>
            </div>
            <div class="top-ic d-flex align-items-center">
              <div class="ic-user">
                <NuxtLink to="/my-account/">
                  <SvgoUserIc/>
                </NuxtLink>
              </div>
              <div class="ic-cart mr-3">
                <a href="javascript:void(0)" @click="toggleCart">
                  <VanBadge class="num-cart" :offset="[-15, -8]" color="transparent" :content="itemsInCart">
                    <SvgoCartIc/>
                  </VanBadge>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </VanSticky>
</template>
<script setup>

const {menus, options} = useGlobalStore();
const {isUpdatingCart, itemsInCart, cartTotal} = storeToRefs(useCartStore());
const {toggleCart} = useCartStore();
const route = useRoute();
const left_menu = menus?.find((menu) => menu.name === "left menu");
const right_menu = menus?.find((menu) => menu.name === "right menu");
// watch(isUpdatingCart.value, () => {
//   console.log(isUpdatingCart.value);
// });


function stripUrl(url) {
  return url.replace("./", "");
}
</script>
<style lang="scss">
.van-sticky {
  &--fixed {
    .top-menu {
      background: #000;
      padding: 10px 0 !important;

      .logo img {
        width: auto !important;
        max-height: 50px !important;
      }
    }

    .ic-cart svg,
    .ic-user svg {
      width: 60% !important;
    }

    .ic-cart-num {
      font-size: 17px;
      -webkit-transform: translate(50%, -85%);
      -ms-transform: translate(50%, -85%);
      transform: translate(50%, -85%);
    }
  }
}

.num-cart {
  .van-badge {
    font-size: 22px;
    border: none;
    font-family: "Assistant", sans-serif;
    color: var(--red);

    .van-sticky--fixed & {
      font-size: 17px;
      -webkit-transform: translate(85%, -40%) !important;
      -ms-transform: translate(85%, -40%) !important;
      transform: translate(85%, -40%) !important;
    }
  }
}
</style>
